<template>
	<LayoutProvider>
		<section class="Top">
			<ContentHeader title="Assign Verticals">
				<div v-if="isLoading" class="ContentHeader-loading">
					<icon name="circle-notch" spin /> Fetching fresh data...
				</div>
			</ContentHeader>

			<p class="bg-white px-3 m-0">
				<span class="text-muted">The changes will be saved immediately</span>
			</p>
		</section>

		<OfsPanel>
			<Datatable
				bordered
				:fields="fields"
				:per-page="10000"
				:items="repos"
				:show-pagination="false"
				sort-by="name"
				:sort-desc="false"
			>
				<template #cell(verticalSiteflow)="{item}">
					<Checkbox
						:repo-verticals="item.verticals"
						vertical="siteflow"
						:is-loading="loaders[`${item.name}-siteflow`]"
						@change="$event => handleChange(item.name, $event, 'siteflow')"
					/>
				</template>
				<template #cell(verticalWorkforce)="{item}">
					<Checkbox
						:repo-verticals="item.verticals"
						vertical="workforce"
						:is-loading="loaders[`${item.name}-workforce`]"
						@change="$event => handleChange(item.name, $event, 'workforce')"
					/>
				</template>
				<template #cell(verticalPiazza)="{item}">
					<Checkbox
						:repo-verticals="item.verticals"
						vertical="piazza"
						:is-loading="loaders[`${item.name}-piazza`]"
						@change="$event => handleChange(item.name, $event, 'piazza')"
					/>
				</template>
				<template #cell(verticalBaas)="{item}">
					<Checkbox
						:repo-verticals="item.verticals"
						vertical="baas"
						:is-loading="loaders[`${item.name}-baas`]"
						@change="$event => handleChange(item.name, $event, 'baas')"
					/>
				</template>
				<template #cell(verticalOther)="{item}">
					<Checkbox
						:repo-verticals="item.verticals"
						vertical="other"
						:is-loading="loaders[`${item.name}-other`]"
						@change="$event => handleChange(item.name, $event, 'other')"
					/>
				</template>
			</Datatable>
		</OfsPanel>
	</LayoutProvider>
</template>

<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import PQueue from 'p-queue';
import { ContentHeader, OfsPanel, Datatable } from '@oneflow/ofs-vue-layout';
import LayoutProvider from '../../../components/layouts/DefaultLayout';
import Checkbox from './Checkbox';

export default {
	components: {
		LayoutProvider,
		ContentHeader,
		OfsPanel,
		Datatable,
		Checkbox
	},
	data() {
		return {
			isLoading: false,
			loaders: {}
		};
	},
	computed: {
		...mapGetters({ repos: 'analytics/repos' }),
		fields() {
			return [
				{ key: 'name', label: 'Name' },
				{ key: 'verticalSiteflow', label: 'SiteFlow' },
				{ key: 'verticalWorkforce', label: 'Workforce' },
				{ key: 'verticalPiazza', label: 'Piazza' },
				{ key: 'verticalBaas', label: 'BaaS' },
				{ key: 'verticalOther', label: 'Other' }
			];
		}
	},
	created() {
		this.fetchData();
		this.queue = new PQueue({ concurrency: 1 });
	},
	methods: {
		...mapActions({
			getAllRepos: 'analytics/getAllRepos',
			addVerticalToRepo: 'analytics/addVerticalToRepo',
			removeVerticalFromRepo: 'analytics/removeVerticalFromRepo'
		}),
		async fetchData() {
			this.isLoading = true;
			try {
				await this.getAllRepos();
			} catch (err) {
				this.$notify({
					type: 'error',
					title: 'Something went wrong trying to fetch the repo list'
				});
			} finally {
				this.isLoading = false;
			}
		},
		async handleChange(repoName, { verticals, added }, selectedVertical) {
			this.loaders = {
				...this.loaders,
				[`${repoName}-${selectedVertical}`]: true
			};
			if (added) {
				await this.queue.add(() => this.addVerticalToRepo({ repoName, verticals: [selectedVertical] }));
			} else {
				await this.queue.add(() => this.removeVerticalFromRepo({ repoName, vertical: selectedVertical }));
			}
			this.loaders = {
				...this.loaders,
				[`${repoName}-${selectedVertical}`]: false
			};
		}
	}
};
</script>

<style lang="scss" scoped>
.ContentHeader {
	background: white;
	position: relative;
	&-loading {
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.Top {
	background: white;
	padding-bottom: 20px;
}
</style>
