<template>
	<div class="panel-inner">
		<h3 class="panel-inner-heading">{{ label }}</h3>
		<scale v-if="showScale && value" :value="value" />
		<div v-else-if="value || description" class="panel-inner-content">
			<span class="d-block panel-title" :class="getClass(value)">
				{{ value }}
			</span>
			<span class="d-block font-italic opacity-50">
				{{ description }}
			</span>
		</div>

		<div v-if="!barChart" class="chart">
			<chart ref="chart" :chart-data="chartData" />
		</div>
		<div v-else class="bar-chart">
			<bar-chart ref="chart" :chart-data="chartData" />
		</div>
	</div>
</template>

<script type="text/javascript">
import Scale from './Scale';
import Chart from './Chart';
import BarChart from './BarChart';

export default {
	components: {
		Scale,
		Chart,
		BarChart
	},
	props: {
		label: {
			type: String,
			default: ''
		},
		value: {
			type: [Number, String],
			default: ''
		},
		chartData: {
			type: Object,
			default: () => {}
		},
		showScale: {
			type: Boolean,
			default: false
		},
		description: {
			type: String,
			default: ''
		},
		barChart: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		getClass(value) {
			if (value === 'Good') {
				return 'text-success';
			}
			return 'text-danger';
		}
	}
};
</script>

<style lang="scss">
.panel-inner-heading {
	font-size: 14px;
	font-weight: bold;
	padding: 15px;
	border-bottom: 1px solid #d3d8e0;
	min-height: 65px;
	line-height: 1.4;
	display: flex;
	align-items: center;
}

.panel-title {
	line-height: 1.2;
	font-size: 20px;
	font-weight: bold;
	padding-bottom: 5px;
}

.panel-inner-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 15px;
}
</style>
