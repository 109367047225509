import _ from 'lodash';
import Vue from 'vue';

export default function(store) {
	const initialState = {
		securityTrend: {},
		sentryTrend: {},
		coverageTrend: {},
		bugBacklogTrend: {},
		defectTrend: {},
		releases: {},
		workflowsCount: {},

		repos: [],
		reposSecurity: {},
		reposCoverage: {},
		reposSentry: {},
		reportUrl: ''
	};
	const state = _.merge(initialState, store.state.analytics);

	store.registerModule('analytics', {
		namespaced: true,
		state,
		actions: {
			async getSecurityTrend({ dispatch, commit }, vertical) {
				const path = vertical === 'overview' ? `security/trend` : `security/vertical/${vertical}/trend`;
				const { data: securityTrend } = await dispatch(
					'api/request',
					{
						method: 'GET',
						path
					},
					{ root: true }
				);

				commit(`SET_SECURITY_TREND`, { securityTrend, key: vertical });
			},
			async getSentryTrend({ dispatch, commit }, vertical) {
				const path = vertical === 'overview' ? `sentry/trend` : `sentry/vertical/${vertical}/trend`;
				const { data: sentryTrend } = await dispatch(
					'api/request',
					{
						method: 'GET',
						path
					},
					{ root: true }
				);

				commit(`SET_SENTRY_TREND`, { sentryTrend, key: vertical });
			},
			async getCoverageTrend({ dispatch, commit }, vertical) {
				const path = vertical === 'overview' ? `coverage/trend` : `coverage/vertical/${vertical}/trend`;
				const { data: coverageTrend } = await dispatch(
					'api/request',
					{
						method: 'GET',
						path
					},
					{ root: true }
				);

				commit(`SET_COVERAGE_TREND`, { coverageTrend, key: vertical });
			},
			async getBugBacklogTrend({ dispatch, commit }, vertical) {
				const path = vertical === 'overview' ? `bug-backlog/trend` : `bug-backlog/vertical/${vertical}/trend`;
				const { data: bugBacklogTrend } = await dispatch(
					'api/request',
					{
						method: 'GET',
						path
					},
					{ root: true }
				);

				commit(`SET_BUG_BACKLOG_TREND`, { bugBacklogTrend, key: vertical });
			},
			async getDefectTrend({ dispatch, commit }, vertical) {
				const path = `defect-ratio/vertical/${vertical}/trend`;
				const { data: defectTrend } = await dispatch(
					'api/request',
					{
						method: 'GET',
						path
					},
					{ root: true }
				);

				commit(`SET_DEFECT_TREND`, { defectTrend, key: vertical });
			},
			async getReport({ dispatch, commit }) {
				const path = `report/overview`;
				const {
					data: { reportUrl }
				} = await dispatch('api/request', { method: 'GET', path }, { root: true });

				commit('SET_REPORT_URL', reportUrl);
			},
			async getAllRepos({ dispatch, commit }) {
				const path = `analytics/repos`;
				const { data } = await dispatch('api/request', { method: 'GET', path }, { root: true });

				commit('SET_REPOS', data);
			},
			async getReleaseStats({ dispatch, commit }, vertical) {
				const path = `analytics/releases/${vertical}`;
				const { data } = await dispatch('api/request', { method: 'GET', path }, { root: true });
				commit(`SET_RELEASE_STAT`, { data, key: vertical });
			},
			async getRepoSecurity({ dispatch, commit }, repoName) {
				const path = `security/repo/${repoName}`;
				const { data } = await dispatch('api/request', { method: 'GET', path }, { root: true });

				commit('SET_REPO_SECURITY', { repoName, data });
			},
			async getRepoCoverage({ dispatch, commit }, repoName) {
				const path = `coverage/repo/${repoName}`;
				const { data } = await dispatch('api/request', { method: 'GET', path }, { root: true });

				commit('SET_REPO_COVERAGE', { repoName, data });
			},
			async getRepoSentryIssues({ dispatch, commit }, repoName) {
				const path = `sentry/repo/${repoName}/count`;
				try {
					const { data } = await dispatch('api/request', { method: 'GET', path }, { root: true });
					commit('SET_REPO_SENTRY_ISSUES', { repoName, data });
				} catch (error) {
					commit('SET_REPO_SENTRY_ISSUES', { repoName, error });
				}
			},
			async addVerticalToRepo({ dispatch, commit }, { repoName, verticals }) {
				const path = `analytics/repo/${repoName}/vertical`;
				const { data } = await dispatch(
					'api/request',
					{ method: 'PUT', path, data: { verticals } },
					{ root: true }
				);
				commit('SET_REPO', data);
			},
			async removeVerticalFromRepo({ dispatch, commit }, { repoName, vertical }) {
				const path = `analytics/repo/${repoName}/vertical/${vertical}`;
				const { data } = await dispatch('api/request', { method: 'DELETE', path }, { root: true });
				commit('SET_REPO', data);
			},
			async getAllWorkflowsCount({ dispatch, commit }) {
				const path = `workflows/count`;
				const { data } = await dispatch('api/request', { method: 'GET', path }, { root: true });

				commit('SET_WORKFLOWS_COUNT', data);
			}
		},
		mutations: {
			SET_SECURITY_TREND(state, { securityTrend, key }) {
				state.securityTrend[key] = securityTrend;
			},
			SET_SENTRY_TREND(state, { sentryTrend, key }) {
				state.sentryTrend[key] = sentryTrend;
			},
			SET_COVERAGE_TREND(state, { coverageTrend, key }) {
				state.coverageTrend[key] = coverageTrend;
			},
			SET_BUG_BACKLOG_TREND(state, { bugBacklogTrend, key }) {
				state.bugBacklogTrend[key] = bugBacklogTrend;
			},
			SET_DEFECT_TREND(state, { defectTrend, key }) {
				state.defectTrend[key] = defectTrend;
			},
			SET_REPORT_URL(state, reportUrl) {
				state.reportUrl = reportUrl;
			},
			SET_REPOS(state, repos) {
				state.repos = repos;
			},
			SET_REPO(state, repo) {
				const index = state.repos.findIndex(r => r.name === repo.name);
				Vue.set(state.repos, index, repo);
			},
			SET_REPO_SECURITY(state, { repoName, data }) {
				state.reposSecurity = {
					...state.reposSecurity,
					[repoName]: data
				};
			},
			SET_REPO_COVERAGE(state, { repoName, data }) {
				state.reposCoverage = {
					...state.reposCoverage,
					[repoName]: data
				};
			},
			SET_REPO_SENTRY_ISSUES(state, { repoName, data, error }) {
				state.reposSentry = {
					...state.reposSentry,
					[repoName]: {
						success: !!data,
						data,
						error
					}
				};
			},
			SET_RELEASE_STAT(state, { data, key }) {
				state.releases[key] = data;
			},
			SET_WORKFLOWS_COUNT(state, workflowsCount) {
				state.workflowsCount = workflowsCount;
			}
		},
		getters: {
			overviewSecurityTrend: state => state.securityTrend['overview'] || [],
			siteflowSecurityTrend: state => state.securityTrend['siteflow'] || [],
			workforceSecurityTrend: state => state.securityTrend['workforce'] || [],
			piazzaSecurityTrend: state => state.securityTrend['piazza'] || [],
			baasSecurityTrend: state => state.securityTrend['baas'] || [],
			otherSecurityTrend: state => state.securityTrend['other'] || [],

			overviewSentryTrend: state => state.sentryTrend['overview'] || [],
			siteflowSentryTrend: state => state.sentryTrend['siteflow'] || [],
			workforceSentryTrend: state => state.sentryTrend['workforce'] || [],
			piazzaSentryTrend: state => state.sentryTrend['piazza'] || [],
			baasSentryTrend: state => state.sentryTrend['baas'] || [],
			otherSentryTrend: state => state.sentryTrend['other'] || [],

			overviewCoverageTrend: state => state.coverageTrend['overview'] || [],
			siteflowCoverageTrend: state => state.coverageTrend['siteflow'] || [],
			workforceCoverageTrend: state => state.coverageTrend['workforce'] || [],
			piazzaCoverageTrend: state => state.coverageTrend['piazza'] || [],
			baasCoverageTrend: state => state.coverageTrend['baas'] || [],
			otherCoverageTrend: state => state.coverageTrend['other'] || [],

			overviewBugBacklogTrend: state => state.bugBacklogTrend['overview'] || [],
			siteflowBugBacklogTrend: state => state.bugBacklogTrend['siteflow'] || [],
			workforceBugBacklogTrend: state => state.bugBacklogTrend['workforce'] || [],
			piazzaBugBacklogTrend: state => state.bugBacklogTrend['piazza'] || [],
			baasBugBacklogTrend: state => state.bugBacklogTrend['baas'] || [],
			otherBugBacklogTrend: state => state.bugBacklogTrend['other'] || [],

			overviewDefectTrend: state => state.defectTrend['overview'] || [],
			siteflowDefectTrend: state => state.defectTrend['siteflow'] || [],
			workforceDefectTrend: state => state.defectTrend['workforce'] || [],
			piazzaDefectTrend: state => state.defectTrend['piazza'] || [],
			baasDefectTrend: state => state.defectTrend['baas'] || [],
			otherDefectTrend: state => state.defectTrend['other'] || [],

			overviewReleases: state => state.releases['overview'],
			siteflowReleases: state => state.releases['siteflow'],
			workforceReleases: state => state.releases['workforce'],
			piazzaReleases: state => state.releases['piazza'],
			baasReleases: state => state.releases['baas'],
			otherReleases: state => state.releases['other'],

			repos: state => state.repos,
			reportUrl: state => state.reportUrl,
			reposSecurity: state => state.reposSecurity,
			reposCoverage: state => state.reposCoverage,
			reposSentry: state => state.reposSentry,

			workflowsCount: state => state.workflowsCount
		}
	});
}
