const menuStructure = [
	{
		title: 'Overview',
		items: [
			{
				items: [{ title: 'Overview', name: 'overview' }]
			}
		]
	},
	{
		title: 'Improvements',
		items: [
			{
				items: [
					{ title: 'Test Coverage', name: 'improvements.test-coverage' },
					{ title: 'Security Coverage', name: 'improvements.security-coverage' },
					{ title: 'Sentry Issues', name: 'improvements.sentry' },
					{ title: 'QA Checkpoints', name: 'improvements.checkpoints' }
				]
			}
		]
	},
	{
		title: 'Settings',
		items: [
			{
				items: [{ title: 'Assign Verticals', name: 'settings.assign-verticals' }]
			}
		]
	}
];

export default menuStructure;
