<template>
	<div>
		<Datatable
			heading="Datatable"
			bordered
			:fields="fields"
			:per-page="10000"
			:items="items"
			:show-pagination="false"
			sort-by="sentry.data.count"
			:sort-desc="true"
		>
			<template #cell(sentry)="{item}">
				<template v-if="item.sentry && item.sentry.success">
					{{ item.sentry.data.count }}
				</template>
				<template v-else-if="item.sentry && item.sentry.error">
					{{ getErrorMessage(item.sentry.error) }}
				</template>
			</template>
			<template #cell(sentry.data.totalUsers)="{item}">
				<template v-if="item.sentry && item.sentry.success">
					{{ item.sentry.data.totalUsers }}
				</template>
			</template>
			<template #cell(impact)="{item}">
				<template v-if="item.sentry && item.sentry.success">
					{{ item.sentry.data.issuesPerUser || '' }}
				</template>
			</template>
			<template #cell(loading)="{item}">
				<icon v-if="repoLoading[item.name]" name="circle-notch" spin />
			</template>
		</Datatable>
	</div>
</template>

<script>
import { Datatable } from '@oneflow/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import PQueue from 'p-queue';

export default {
	components: { Datatable },
	props: {
		repos: {
			type: Array,
			default: function() {
				return [];
			}
		}
	},
	data() {
		return {
			repoLoading: {}
		};
	},
	computed: {
		...mapGetters({ reposSentry: 'analytics/reposSentry' }),
		fields() {
			return [
				{ key: 'name', label: 'Name' },
				{ key: 'sentry', label: 'Number of sentry issues', sortable: true },
				{ key: 'sentry.data.totalUsers', label: 'Number of impacted users', sortable: true },
				{ key: 'sentry.data.issuesPerUser', label: 'Issues per user', sortable: true },
				{ key: 'loading', label: '', class: 'Column-loader' }
			];
		},
		items() {
			return (this.repos || []).map(repo => {
				return { ...repo, sentry: this.reposSentry[repo.name] };
			});
		}
	},
	watch: {
		repos: {
			immediate: true,
			handler: 'reposChanged'
		}
	},
	created() {
		this.queue = new PQueue({ concurrency: 10 });
	},
	methods: {
		...mapActions({ getRepoSentryIssues: 'analytics/getRepoSentryIssues' }),
		reposChanged: _.debounce(function() {
			this.repos.forEach(({ name }) =>
				this.queue.add(async () => {
					try {
						this.repoLoading = { ...this.repoLoading, [name]: true };
						await this.getRepoSentryIssues(name);
					} finally {
						this.repoLoading[name] = false;
					}
				})
			);
		}, 200),
		getErrorMessage(error) {
			if (error.message && error.message.includes(404)) return 'No sentry project found';

			return error.message;
		}
	}
};
</script>

<style>
.Column-loader {
	min-width: 50px;
}
</style>
