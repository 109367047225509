<template>
	<AppLayout>
		<TopBar slot="top-bar">
			<img
				slot="logo"
				src="images/product-insights.svg"
				alt="HP Product Insights"
				title="HP Product Insights"
			/>
			<template slot="account">
				<b-button v-t="'Sign Out'" class="text-white px-0" variant="link" size="sm" @click="onLogOutClick" />
			</template>
		</TopBar>
		<div slot="left-menu">
			<slot name="left-panel"><LeftPanel /></slot>
		</div>
		<div slot="content">
			<slot></slot>
			<VersionNotification />
		</div>
	</AppLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { AppLayout, LeftPanel, TopBar } from '@oneflow/ofs-vue-layout';
import VersionNotification from '../VersionNotification';

export default {
	components: {
		AppLayout,
		TopBar,
		LeftPanel,
		VersionNotification
	},
	methods: {
		...mapActions('auth', ['logout']),
		async onLogOutClick() {
			await this.logout();
		}
	}
};
</script>
