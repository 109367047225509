<template>
	<div layout="div" class="LoginPage">
		<LoginForm
			class="LoginPage-form"
			:config="loginConfig"
			:on-login-request="handleLoginRequest"
			:on-reset-password-request="handleResetPasswordRequest"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { LoginForm } from '@oneflow/ofs-vue-layout';
import LayoutProvider from '../../components/layouts/DefaultLayout';

export default {
	name: 'Login',
	components: {
		LoginForm
	},
	data() {
		return {
			loginConfig: {
				signInText: this.$t('Sign In'),
				emailPlaceholder: this.$t('Email'),
				passwordPlaceholder: this.$t('Password'),
				loginText: this.$t('Log In'),
				loginErrorText: this.$t('Login Error'),
				loginErrorUserMessage: this.$t('Trouble Logging In?'),
				resetPasswordText: this.$t('Reset Password'),
				resetPasswordButtonText: this.$t('Reset my password')
			}
		};
	},
	computed: {
		...mapGetters('auth', ['isAuthenticated']),
		redirectTo() {
			const { redirectTo } = this.$route.query || {};
			return redirectTo;
		}
	},
	created() {
		if (this.isAuthenticated) this.$router.push('/overview');
	},
	methods: {
		...mapActions('auth', ['login', 'resetPassword']),
		async handleLoginRequest(credentials) {
			const response = await this.login(credentials);
			const redirectTo = this.redirectTo || '/overview';
			this.$router.push(redirectTo);
			return response;
		},
		async handleResetPasswordRequest({ email }) {
			try {
				await this.resetPassword(email);
				this.$notify({
					type: 'success',
					title: 'Success',
					text: this.$t('Email with instructions has been sent successfully')
				});
			} catch (err) {
				this.$notify({
					type: 'error',
					title: this.$t('Error'),
					text: this.$t('An error occurred while resetting password')
				});
			}
		}
	}
};
</script>

<style lang="scss">
.LoginPage {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;

	&-form {
		min-width: 250px;
	}
}
</style>
