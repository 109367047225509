var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Datatable',{attrs:{"heading":"Datatable","bordered":"","fields":_vm.fields,"per-page":10000,"items":_vm.items,"show-pagination":false,"sort-by":"sentry.data.count","sort-desc":true},scopedSlots:_vm._u([{key:"cell(sentry)",fn:function(ref){
var item = ref.item;
return [(item.sentry && item.sentry.success)?[_vm._v(" "+_vm._s(item.sentry.data.count)+" ")]:(item.sentry && item.sentry.error)?[_vm._v(" "+_vm._s(_vm.getErrorMessage(item.sentry.error))+" ")]:_vm._e()]}},{key:"cell(sentry.data.totalUsers)",fn:function(ref){
var item = ref.item;
return [(item.sentry && item.sentry.success)?[_vm._v(" "+_vm._s(item.sentry.data.totalUsers)+" ")]:_vm._e()]}},{key:"cell(impact)",fn:function(ref){
var item = ref.item;
return [(item.sentry && item.sentry.success)?[_vm._v(" "+_vm._s(item.sentry.data.issuesPerUser || '')+" ")]:_vm._e()]}},{key:"cell(loading)",fn:function(ref){
var item = ref.item;
return [(_vm.repoLoading[item.name])?_c('icon',{attrs:{"name":"circle-notch","spin":""}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }