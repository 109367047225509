<template>
	<div>
		<div class="number-row">
			<section v-for="(panel, index) in panels" :key="`panel-${index}`" class="panel">
				<panel
					:label="panel.label"
					:value="panel.value"
					:description="panel.description"
					:chart-data="panel.chartData"
					:show-scale="panel.showScale"
				/>
			</section>
		</div>
		<section>
			<panel :label="'Releases'" :chart-data="releaseChartData" :bar-chart="true" />
		</section>
	</div>
</template>

<script type="text/javascript">
import Panel from './Panel';

export default {
	components: {
		Panel
	},
	props: {
		panels: {
			type: Array,
			default: () => {
				[];
			}
		},
		releaseChartData: {
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style lang="scss">
.panel {
	flex: 1;

	&-inner {
		background: #fff;
		border: 1px solid #eee;
		box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
		border-radius: 4px;
	}

	&-heading {
		padding: 15px;
		font-size: 18px;
	}
}

.panel-row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	@media all and (min-width: 600px) {
		.panel {
			min-width: 50%;
			max-width: 50%;
		}
	}

	@media all and (min-width: 1000px) {
		.panel {
			min-width: 33.333%;
			max-width: 33.333%;
		}
	}
}

.number-row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	@media all and (min-width: 600px) {
		.panel {
			min-width: 50%;
			max-width: 50%;
		}
	}

	@media all and (min-width: 1000px) {
		.panel {
			min-width: 20%;
			max-width: 25%;
		}
	}
}
</style>
