var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutProvider',[_c('section',{staticClass:"Top"},[_c('ContentHeader',{attrs:{"title":"Assign Verticals"}},[(_vm.isLoading)?_c('div',{staticClass:"ContentHeader-loading"},[_c('icon',{attrs:{"name":"circle-notch","spin":""}}),_vm._v(" Fetching fresh data... ")],1):_vm._e()]),_c('p',{staticClass:"bg-white px-3 m-0"},[_c('span',{staticClass:"text-muted"},[_vm._v("The changes will be saved immediately")])])],1),_c('OfsPanel',[_c('Datatable',{attrs:{"bordered":"","fields":_vm.fields,"per-page":10000,"items":_vm.repos,"show-pagination":false,"sort-by":"name","sort-desc":false},scopedSlots:_vm._u([{key:"cell(verticalSiteflow)",fn:function(ref){
var item = ref.item;
return [_c('Checkbox',{attrs:{"repo-verticals":item.verticals,"vertical":"siteflow","is-loading":_vm.loaders[((item.name) + "-siteflow")]},on:{"change":function ($event) { return _vm.handleChange(item.name, $event, 'siteflow'); }}})]}},{key:"cell(verticalWorkforce)",fn:function(ref){
var item = ref.item;
return [_c('Checkbox',{attrs:{"repo-verticals":item.verticals,"vertical":"workforce","is-loading":_vm.loaders[((item.name) + "-workforce")]},on:{"change":function ($event) { return _vm.handleChange(item.name, $event, 'workforce'); }}})]}},{key:"cell(verticalPiazza)",fn:function(ref){
var item = ref.item;
return [_c('Checkbox',{attrs:{"repo-verticals":item.verticals,"vertical":"piazza","is-loading":_vm.loaders[((item.name) + "-piazza")]},on:{"change":function ($event) { return _vm.handleChange(item.name, $event, 'piazza'); }}})]}},{key:"cell(verticalBaas)",fn:function(ref){
var item = ref.item;
return [_c('Checkbox',{attrs:{"repo-verticals":item.verticals,"vertical":"baas","is-loading":_vm.loaders[((item.name) + "-baas")]},on:{"change":function ($event) { return _vm.handleChange(item.name, $event, 'baas'); }}})]}},{key:"cell(verticalOther)",fn:function(ref){
var item = ref.item;
return [_c('Checkbox',{attrs:{"repo-verticals":item.verticals,"vertical":"other","is-loading":_vm.loaders[((item.name) + "-other")]},on:{"change":function ($event) { return _vm.handleChange(item.name, $event, 'other'); }}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }