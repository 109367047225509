var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"p-3"},[_c('ContentHeader',{staticClass:"mb-3",attrs:{"title":"Suggested Actions","no-padding":""}}),_c('Datatable',{attrs:{"heading":"Datatable","bordered":"","fields":_vm.opFields,"per-page":10000,"items":_vm.opItems,"show-pagination":false},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [(item.coverage && item.coverage.coverage)?[_c('a',{attrs:{"href":("https://codecov.io/gh/Oneflow/" + (item.name)),"target":"_blank"}},[_vm._v(_vm._s(item.name))])]:_vm._e()]}},{key:"cell(coverage)",fn:function(ref){
var item = ref.item;
return [(item.coverage && item.coverage.coverage)?[_vm._v(" "+_vm._s(item.coverage.coverage === '-' ? 'No codecov integration' : ((item.coverage.coverage) + "%"))+" ")]:_vm._e()]}},{key:"cell(loading)",fn:function(ref){
var item = ref.item;
return [(_vm.repoLoading[item.name])?_c('icon',{attrs:{"name":"circle-notch","spin":""}}):_vm._e()]}}])})],1),_c('div',{staticClass:"p-3"},[_c('ContentHeader',{staticClass:"mb-3",attrs:{"title":"Full List","no-padding":""}}),_c('Datatable',{attrs:{"heading":"Datatable","bordered":"","fields":_vm.fields,"per-page":10000,"items":_vm.items,"show-pagination":false,"sort-by":"coverage.coverage","sort-desc":true},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [(item.coverage && item.coverage.coverage)?[_c('a',{attrs:{"href":("https://codecov.io/gh/Oneflow/" + (item.name)),"target":"_blank"}},[_vm._v(_vm._s(item.name))])]:_vm._e()]}},{key:"cell(coverage)",fn:function(ref){
var item = ref.item;
return [(item.coverage && item.coverage.coverage)?[_vm._v(" "+_vm._s(item.coverage.coverage === '-' ? 'No codecov integration' : ((item.coverage.coverage) + "%"))+" ")]:_vm._e()]}},{key:"cell(loading)",fn:function(ref){
var item = ref.item;
return [(_vm.repoLoading[item.name])?_c('icon',{attrs:{"name":"circle-notch","spin":""}}):_vm._e()]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }