<template>
	<LayoutProvider class="Overview">
		<section class="Top">
			<ContentHeader title="Product & Engineering" data-test-id="pageTitle">
				<div v-if="isLoading" class="ContentHeader-loading">
					<icon name="circle-notch" spin /> Fetching fresh data...
				</div>
				<b-btn
					v-else
					v-t="'Export'"
					type="button"
					variant="primary"
					:disabled="!exportButtonActive"
					@click="openExport"
				/>
			</ContentHeader>
		</section>
		<b-tabs v-model="tabIndex" content-class="mt-3">
			<b-tab title="Overview">
				<div v-if="tabIndex === 0" class="wrapper">
					<statusRow :panels="overviewPanels" :release-chart-data="releasesDatasets.overview" />
				</div>
			</b-tab>
			<b-tab title="SiteFlow">
				<div v-if="tabIndex === 1" class="wrapper">
					<statusRow :panels="siteflowPanels" :release-chart-data="releasesDatasets.siteflow" />
				</div>
			</b-tab>
			<b-tab title="Workforce">
				<div v-if="tabIndex === 2" class="wrapper">
					<statusRow :panels="workforcePanels" :release-chart-data="releasesDatasets.workforce" />
				</div>
			</b-tab>
			<b-tab title="Piazza">
				<div v-if="tabIndex === 3" class="wrapper">
					<statusRow :panels="piazzaPanels" :release-chart-data="releasesDatasets.piazza" />
				</div>
			</b-tab>
			<b-tab title="BaaS">
				<div v-if="tabIndex === 4" class="wrapper">
					<statusRow :panels="baasPanels" :release-chart-data="releasesDatasets.baas" />
				</div>
			</b-tab>
			<b-tab title="Other">
				<div v-if="tabIndex === 5" class="wrapper">
					<statusRow :panels="otherPanels" :release-chart-data="releasesDatasets.other" />
				</div>
			</b-tab>
		</b-tabs>
		<div slot="left-panel"></div>
	</LayoutProvider>
</template>

<script type="text/javascript">
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { ContentHeader } from '@oneflow/ofs-vue-layout';
import Promise from 'bluebird';
import LayoutProvider from '../../components/layouts/DefaultLayout';
import StatusRow from '../../components/StatusRow';

export default {
	components: {
		LayoutProvider,
		StatusRow,
		ContentHeader
	},
	data() {
		return {
			tabIndex: 0,
			isLoading: false,
			exportButtonActive: true,
			verticals: ['overview', 'siteflow', 'workforce', 'piazza', 'baas', 'other']
		};
	},
	computed: {
		...mapGetters({
			reportUrl: 'analytics/reportUrl',
			overviewSecurityTrend: 'analytics/overviewSecurityTrend',
			siteflowSecurityTrend: 'analytics/siteflowSecurityTrend',
			workforceSecurityTrend: 'analytics/workforceSecurityTrend',
			piazzaSecurityTrend: 'analytics/piazzaSecurityTrend',
			baasSecurityTrend: 'analytics/baasSecurityTrend',
			otherSecurityTrend: 'analytics/otherSecurityTrend',

			overviewTestCoverageTrend: 'analytics/overviewCoverageTrend',
			siteflowTestCoverageTrend: 'analytics/siteflowCoverageTrend',
			workforceTestCoverageTrend: 'analytics/workforceCoverageTrend',
			piazzaTestCoverageTrend: 'analytics/piazzaCoverageTrend',
			baasTestCoverageTrend: 'analytics/baasCoverageTrend',
			otherTestCoverageTrend: 'analytics/otherCoverageTrend',

			overviewBugBacklogTrend: 'analytics/overviewBugBacklogTrend',
			siteflowBugBacklogTrend: 'analytics/siteflowBugBacklogTrend',
			workforceBugBacklogTrend: 'analytics/workforceBugBacklogTrend',
			piazzaBugBacklogTrend: 'analytics/piazzaBugBacklogTrend',
			baasBugBacklogTrend: 'analytics/baasBugBacklogTrend',
			otherBugBacklogTrend: 'analytics/otherBugBacklogTrend',

			overviewDefectTrend: 'analytics/overviewDefectTrend',
			siteflowDefectTrend: 'analytics/siteflowDefectTrend',
			workforceDefectTrend: 'analytics/workforceDefectTrend',
			piazzaDefectTrend: 'analytics/piazzaDefectTrend',
			baasDefectTrend: 'analytics/baasDefectTrend',
			otherDefectTrend: 'analytics/otherDefectTrend',

			overviewSentryTrend: 'analytics/overviewSentryTrend',
			siteflowSentryTrend: 'analytics/siteflowSentryTrend',
			workforceSentryTrend: 'analytics/workforceSentryTrend',
			piazzaSentryTrend: 'analytics/piazzaSentryTrend',
			baasSentryTrend: 'analytics/baasSentryTrend',
			otherSentryTrend: 'analytics/otherSentryTrend',

			overviewReleases: 'analytics/overviewReleases',
			siteflowReleases: 'analytics/siteflowReleases',
			workforceReleases: 'analytics/workforceReleases',
			piazzaReleases: 'analytics/piazzaReleases',
			baasReleases: 'analytics/baasReleases',
			otherReleases: 'analytics/otherReleases'
		}),
		releasesDatasets() {
			const chartData = {};
			_.forEach(this.verticals, vertical => {
				chartData[vertical] = { labels: [], datasets: [] };
			});

			if (_.some(this.verticals, vertical => _.isEmpty(this[`${vertical}Releases`]))) {
				return chartData;
			}

			const setReleaseDataset = vertical => {
				const releases = this[`${vertical}Releases`];
				const currentYearReleases = releases.slice(0, 12).reverse();

				_.forEach(currentYearReleases, release => {
					const releaseDate = moment(release.date);
					const currentYearIssueCount = release.releaseCount;
					const prevYearStat = _.find(releases, {
						date: moment(releaseDate)
							.subtract(1, 'year')
							.toISOString()
					});
					const prevYearIssueCount = prevYearStat?.releaseCount;

					let percentageDiff = ((currentYearIssueCount / prevYearIssueCount) * 100 - 100).toFixed(1);
					if (!prevYearIssueCount) percentageDiff = currentYearIssueCount * 100;

					chartData[vertical].labels.push(
						`${releaseDate.format('MMM')} (${percentageDiff > 0 ? `+${percentageDiff}` : percentageDiff}%)`
					);

					let currentYearDataset = _.find(chartData[vertical].datasets, { label: 'Last 12 months' });
					const lineColor = percentageDiff <= -20 ? '#FF8C00' : '#28df99';
					if (!currentYearDataset) {
						currentYearDataset = {
							label: 'Last 12 months',
							backgroundColor: [],
							barPercentage: 1.8,
							data: []
						};
						chartData[vertical].datasets.push(currentYearDataset);
					}

					currentYearDataset.data.push(currentYearIssueCount);
					currentYearDataset.backgroundColor.push(lineColor);

					let prevYearDataset = _.find(chartData[vertical].datasets, { label: '12 months before that' });
					if (!prevYearDataset) {
						prevYearDataset = {
							label: '12 months before that',
							backgroundColor: '#99f3bd',
							data: [],
							barPercentage: 2
						};
						chartData[vertical].datasets.push(prevYearDataset);
					}
					prevYearDataset.data.push(prevYearIssueCount);
				});

				chartData[vertical].datasets.push({
					label: '> 20% decrease from previous year. Investigation required',
					backgroundColor: '#FF8C00'
				});
			};

			_.forEach(this.verticals, vertical => {
				setReleaseDataset(vertical);
			});

			return chartData;
		},
		overviewPanels() {
			return this.generatePanel('overview');
		},
		siteflowPanels() {
			return this.generatePanel('siteflow');
		},
		workforcePanels() {
			return this.generatePanel('workforce');
		},
		piazzaPanels() {
			return this.generatePanel('piazza');
		},
		baasPanels() {
			return this.generatePanel('baas');
		},
		otherPanels() {
			return this.generatePanel('other');
		}
	},
	async mounted() {
		this.isLoading = true;
		try {
			const promises = [];
			_.forEach(this.verticals, vertical => {
				_.forEach(
					[
						'getSecurityTrend',
						'getSentryTrend',
						'getCoverageTrend',
						'getBugBacklogTrend',
						'getDefectTrend',
						'getReleaseStats'
					],
					method => {
						promises.push(this[method](vertical).catch(() => {}));
					}
				);
			});
			await Promise.all(promises);
		} finally {
			this.isLoading = false;
		}
	},
	methods: {
		...mapActions({
			getSecurityTrend: 'analytics/getSecurityTrend',
			getReleaseStats: 'analytics/getReleaseStats',
			getSentryTrend: 'analytics/getSentryTrend',
			getCoverageTrend: 'analytics/getCoverageTrend',
			getBugBacklogTrend: 'analytics/getBugBacklogTrend',
			getDefectTrend: 'analytics/getDefectTrend',
			getReport: 'analytics/getReport'
		}),
		async openExport() {
			try {
				this.exportButtonActive = false;
				await this.getReport();
				window.open(this.reportUrl, '_blank');
			} finally {
				this.exportButtonActive = true;
			}
		},
		getClass(value) {
			if (value === 'Good') {
				return 'text-success';
			}
			return 'text-danger';
		},
		generatePanel(vertical) {
			const avgCoverage = _.mean(this[`${vertical}TestCoverageTrend`].data);
			const securityAboveThreshold = this[`${vertical}SecurityTrend`].data?.[0] >= 0;
			let coverageStatus = 'Good';
			if (avgCoverage <= 80) {
				coverageStatus = avgCoverage <= 80 && avgCoverage >= 60 ? 'Improvements needed' : 'Concerned';
			}
			const bugBacklogAboveThreshold = this[`${vertical}BugBacklogTrend`].data?.[0] >= 0;
			const sentryTrend = this[`${vertical}SentryTrend`].data;
			const sentryBelowThreshold = _.isEmpty(sentryTrend) || sentryTrend[0] <= sentryTrend[1];
			return [
				{
					label: 'Test Coverage',
					value: coverageStatus,
					description: avgCoverage + '%',
					chartData: {
						labels: ['Older', 'Two Weeks Ago', 'One Week Ago', 'This Week'],
						datasets: [
							{
								data: _.reverse(_.clone(this[`${vertical}TestCoverageTrend`].data))
							}
						]
					},
					showScale: false
				},
				{
					label: 'Security',
					value: securityAboveThreshold ? 'Good' : 'Concerned',
					description: securityAboveThreshold ? 'No Critical Risks' : 'Critical Risks',
					chartData: {
						labels: ['Older', 'Two Weeks Ago', 'One Week Ago', 'This Week'],
						datasets: [
							{
								data: (_.reverse(_.clone(this[`${vertical}SecurityTrend`].data)) || []).map(Math.abs)
							}
						]
					},
					showScale: false
				},
				{
					label: 'Open Bugs',
					value: bugBacklogAboveThreshold ? 'Good' : 'Concerned',
					description: bugBacklogAboveThreshold ? 'Clear in 6 months' : 'Backlog > 6 Months',
					chartData: {
						labels: ['Older', 'Two Months Ago', 'One Month Ago', 'This Month'],
						datasets: [
							{
								data: _.reverse(_.clone(this[`${vertical}BugBacklogTrend`].data))
							}
						]
					},
					showScale: false
				},
				{
					label: 'Sentry Issues',
					value: sentryBelowThreshold ? 'Good' : 'Concerned',
					description: sentryBelowThreshold ? 'No Critical Risks' : 'Critical Risks',
					chartData: {
						labels: ['Older', 'Two Weeks Ago', 'One Week Ago', 'This Week'],
						datasets: [
							{
								data: _.reverse(_.clone(this[`${vertical}SentryTrend`].data))
							}
						]
					},
					showScale: false
				}
			];
		}
	}
};
</script>

<style lang="scss">
.Overview {
	.ContentHeader {
		background: white;
		position: relative;
		&-loading {
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.Top {
		background: white;
		padding-bottom: 20px;
	}

	.wrapper {
		display: flex;
		flex-direction: column;
		padding: 10px;

		.panel-inner {
			margin: 10px;
		}
	}

	.chart canvas {
		width: 100% !important;
		height: 100px !important;
	}
}
</style>
