<template>
	<div>
		<div class="p-3">
			<ContentHeader title="Suggested Actions" no-padding class="mb-3" />
			<Datatable
				heading="Datatable"
				bordered
				:fields="opFields"
				:per-page="10000"
				:items="opItems"
				:show-pagination="false"
			>
				<template #cell(name)="{item}">
					<template v-if="item.coverage && item.coverage.coverage">
						<a :href="`https://codecov.io/gh/Oneflow/${item.name}`" target="_blank">{{ item.name }}</a>
					</template>
				</template>
				<template #cell(coverage)="{item}">
					<template v-if="item.coverage && item.coverage.coverage">
						{{ item.coverage.coverage === '-' ? 'No codecov integration' : `${item.coverage.coverage}%` }}
					</template>
				</template>
				<template #cell(loading)="{item}">
					<icon v-if="repoLoading[item.name]" name="circle-notch" spin />
				</template>
			</Datatable>
		</div>
		<div class="p-3">
			<ContentHeader title="Full List" no-padding class="mb-3" />
			<Datatable
				heading="Datatable"
				bordered
				:fields="fields"
				:per-page="10000"
				:items="items"
				:show-pagination="false"
				sort-by="coverage.coverage"
				:sort-desc="true"
			>
				<template #cell(name)="{item}">
					<template v-if="item.coverage && item.coverage.coverage">
						<a :href="`https://codecov.io/gh/Oneflow/${item.name}`" target="_blank">{{ item.name }}</a>
					</template>
				</template>
				<template #cell(coverage)="{item}">
					<template v-if="item.coverage && item.coverage.coverage">
						{{ item.coverage.coverage === '-' ? 'No codecov integration' : `${item.coverage.coverage}%` }}
					</template>
				</template>
				<template #cell(loading)="{item}">
					<icon v-if="repoLoading[item.name]" name="circle-notch" spin />
				</template>
			</Datatable>
		</div>
	</div>
</template>

<script>
import { Datatable, ContentHeader } from '@oneflow/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import PQueue from 'p-queue';

export default {
	components: { Datatable, ContentHeader },
	props: {
		repos: {
			type: Array,
			default: function() {
				return [];
			}
		}
	},
	data() {
		return {
			repoLoading: {}
		};
	},
	computed: {
		...mapGetters({ reposCoverage: 'analytics/reposCoverage' }),
		fields() {
			return [
				{ key: 'name', label: 'Name' },
				{ key: 'coverage', label: 'Coverage', sortable: true },
				{ key: 'loading', label: '', class: 'Column-loader' }
			];
		},
		items() {
			return (this.repos || []).map(repo => {
				return { ...repo, coverage: this.reposCoverage[repo.name] };
			});
		},
		opFields() {
			return [
				{ key: 'name', label: 'Name' },
				{ key: 'coverage', label: 'Coverage' },
				{ key: 'opportunity', label: 'Opportunity' },
				{ key: 'loading', label: '', class: 'Column-loader' }
			];
		},
		opItems() {
			// set up new variables with default of 0 coverage
			let itemNear80Cov = { coverage: { coverage: 0 } };
			let itemNear60Cov = { coverage: { coverage: 0 } };
			let itemNear40Cov = { coverage: { coverage: 0 } };
			let itemNoCoverage = {};

			// for each repo, get the closest repo to the banding threshold
			this.items.forEach(item => {
				if (item.coverage && item.coverage.coverage) {
					if (item.coverage.coverage < 80 && item.coverage.coverage > 60) {
						if (itemNear80Cov.coverage.coverage < item.coverage.coverage) {
							itemNear80Cov = item;
						}
					} else if (item.coverage.coverage < 60 && item.coverage.coverage > 40) {
						if (itemNear60Cov.coverage.coverage < item.coverage.coverage) {
							itemNear60Cov = item;
						}
					} else if (item.coverage.coverage < 40) {
						if (itemNear40Cov.coverage.coverage < item.coverage.coverage) {
							itemNear40Cov = item;
						}
					} else if (item.coverage.coverage === '-') {
						itemNoCoverage = item;
					}
				}
			});

			// highlight why we have highlighted these repos
			itemNear80Cov.opportunity = `Only ${80 - itemNear80Cov.coverage.coverage}% from 80% target`;
			itemNear60Cov.opportunity = `Only ${60 - itemNear60Cov.coverage.coverage}% from 60% banding`;
			itemNear40Cov.opportunity = `Only ${40 - itemNear40Cov.coverage.coverage}% from 40% banding`;
			itemNoCoverage.opportunity = `Add CodeCov integration`;

			// only return the opportunity if there is an item to act on, otherwise we have blank rows
			return [
				...(itemNear80Cov.coverage.coverage > 0 ? [itemNear80Cov] : []),
				...(itemNear60Cov.coverage.coverage > 0 ? [itemNear60Cov] : []),
				...(itemNear40Cov.coverage.coverage > 0 ? [itemNear40Cov] : []),
				...(itemNoCoverage.name ? [itemNoCoverage] : [])
			];
		}
	},
	watch: {
		repos: {
			immediate: true,
			handler: 'reposChanged'
		}
	},
	created() {
		this.queue = new PQueue({ concurrency: 10 });
	},
	methods: {
		...mapActions({ getRepoCoverage: 'analytics/getRepoCoverage' }),
		reposChanged: _.debounce(function() {
			this.repos.forEach(({ name }) =>
				this.queue.add(async () => {
					try {
						this.repoLoading = { ...this.repoLoading, [name]: true };
						await this.getRepoCoverage(name);
					} finally {
						this.repoLoading[name] = false;
					}
				})
			);
		}, 200)
	}
};
</script>

<style>
.Column-loader {
	min-width: 50px;
}
</style>
