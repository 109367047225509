import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export default function(store) {
	store.registerModule('versionNotification', {
		namespaced: true,
		state: {
			showNotification: false
		},
		getters,
		actions,
		mutations
	});
}
