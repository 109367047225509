<script>
// CommitChart.js
import { Bar } from 'vue-chartjs';

export default {
	extends: Bar,
	props: {
		chartData: {
			type: Object,
			default: () => {}
		}
	},
	data: () => ({
		options: {
			barValueSpacing: 20,
			scales: {
				yAxes: [
					{
						ticks: {
							beginAtZero: true
						}
					}
				]
			},
			responsive: true,
			maintainAspectRatio: false,
			legend: {
				onClick: e => e.stopPropagation()
			}
		}
	}),
	watch: {
		chartData: {
			immediate: false,
			handler() {
				if (this.chartData) {
					this.renderBarChart();
				}
			}
		}
	},
	mounted() {
		this.renderBarChart();
	},
	methods: {
		renderBarChart() {
			this.renderChart(this.chartData, this.options);
		}
	}
};
</script>
