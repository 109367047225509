import tokens from '../../../tokens/en.json';

export default function createActions($i18n) {
	const setLanguage = async ({ commit }, { lang }) => {
		$i18n.locale = lang;
		$i18n.setLocaleMessage(lang, tokens);
		document.querySelector('html').setAttribute('lang', lang);
		commit('SET_LANGUAGE', { lang });
		commit('SET_TOKENS', { tokens });
	};

	return { setLanguage };
}
