<script>
import { Line, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default {
	extends: Line,
	mixins: [reactiveProp],
	props: {
		chartData: {
			type: Object,
			default: () => {}
		}
	},
	data: () => ({
		options: {
			animation: {
				duration: 0 // general animation time
			},
			responsive: false,
			legend: {
				display: false
			},
			elements: {
				line: {
					borderColor: '#008ec7',
					borderWidth: 3,
					backgroundColor: '#def6ff'
				},
				point: {
					radius: 0
				}
			},
			tooltips: {
				enabled: false
			},
			scales: {
				yAxes: [
					{
						display: false
					}
				],
				xAxes: [
					{
						display: false
					}
				]
			}
		}
	}),
	watch: {
		chartData: {
			handler() {
				if (this.chartData) {
					this.renderChart(this.chartData, this.options);
				}
			}
		}
	},
	mounted() {
		if (this.chartData) {
			this.renderChart(this.chartData, this.options);
		}
	}
};
</script>
