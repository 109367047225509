<template>
	<div name="app" :data-version="version">
		<component :is="layout" v-bind="templateProps">
			<router-view :layout.sync="layout" @update:templateProps="handleUpdateProps" />
		</component>
	</div>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';

export default {
	data: () => ({
		version: process.env.VERSION,
		layout: 'div',
		templateProps: null
	}),
	computed: {
		...mapGetters({
			isAuthenticated: 'auth/isAuthenticated',
			activeTopItem: 'menu/activeTopItem',
			activeSubmenu: 'menu/activeSubmenu',
			lang: 'lang/lang'
		})
	},
	watch: {
		isAuthenticated: {
			immediate: true,
			async handler() {
				this.redirectIfNotAuth();
			}
		}
	},
	created() {
		this.setLanguage({ lang: this.lang });
		this.$router.afterEach(this.redirectIfNotAuth);
	},
	methods: {
		...mapActions({
			setLanguage: 'lang/setLanguage'
		}),
		handleUpdateProps(props) {
			this.templateProps = props;
		},
		redirectIfNotAuth() {
			if (!this.isAuthenticated && this.$route.name !== 'login') this.$router.push('/login');
		}
	}
};
</script>
