/* eslint-disable no-console */

import { register } from 'register-service-worker';
import vuex from './vuex';

const notifyUserAboutUpdate = () => {
	vuex.dispatch('versionNotification/showNotification');
};

if (process.env.NODE_ENV === 'production') {
	let started;
	register(`${process.env.BASE_URL}service-worker.js`, {
		ready() {
			started = new Date();
			console.log(
				// eslint-disable-next-line prettier/prettier
				'App is being served from cache by a service worker.\n' +
					'For more details, visit https://goo.gl/AFskqB'
			);
		},
		registered() {
			console.log('Service worker has been registered.');
		},
		cached() {
			console.log('Content has been cached for offline use.');
		},
		updatefound() {
			console.log('New content is downloading.');
		},
		updated(registration) {
			console.log('New content is available; please refresh.');
			registration.waiting.postMessage({ action: 'skipWaiting' });
		},
		offline() {
			console.log('No internet connection found. App is running in offline mode.');
		},
		error(error) {
			console.error('Error during service worker registration:', error);
		}
	});

	if (navigator.serviceWorker) {
		navigator.serviceWorker.addEventListener('controllerchange', () => {
			const updated = new Date();

			if (updated - started < 1000) {
				return window.location.reload(true);
			}
			console.log('should notify');
			notifyUserAboutUpdate();
		});
	}
}
