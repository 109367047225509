import loginRoutes from '../containers/Login';
import overviewRoutes from '../containers/Overview';
import improvementsRoutes from '../containers/Improvements';
import settingsRoutes from '../containers/Settings';

const routes = [
	...loginRoutes,
	...overviewRoutes,
	...improvementsRoutes,
	...settingsRoutes,
	{
		path: '/',
		redirect: '/overview'
	}
];

export default routes;
