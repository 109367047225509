<template>
	<LayoutProvider>
		<section class="Top">
			<ContentHeader title="Test Coverage" data-test-id="pageTitle">
				<div v-if="isLoading" class="ContentHeader-loading">
					<icon name="circle-notch" spin /> Fetching fresh data...
				</div>
			</ContentHeader>

			<p class="bg-white px-3 m-0">
				What are our repos current unit test code coverage?
			</p>
		</section>

		<b-tabs content-class="mt-3" lazy>
			<b-tab title="SiteFlow">
				<div class="wrapper"><Table :repos="siteflowRepos" /></div>
			</b-tab>
			<b-tab title="Workforce">
				<div class="wrapper"><Table :repos="workforceRepos" /></div>
			</b-tab>
			<b-tab title="Piazza">
				<div class="wrapper"><Table :repos="piazzaRepos" /></div>
			</b-tab>
			<b-tab title="BaaS">
				<div class="wrapper"><Table :repos="baasRepos" /></div>
			</b-tab>
			<b-tab title="Other">
				<div class="wrapper"><Table :repos="otherRepos" /></div>
			</b-tab>
		</b-tabs>
	</LayoutProvider>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { ContentHeader } from '@oneflow/ofs-vue-layout';
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/circle-notch';
import Table from './Table';
import LayoutProvider from '../../../components/layouts/DefaultLayout';

export default {
	components: { LayoutProvider, Table, Icon, ContentHeader },
	data() {
		return { isLoading: false };
	},
	computed: {
		...mapGetters({
			repos: 'analytics/repos'
		}),
		siteflowRepos() {
			return _.filter(this.repos, repo => repo?.verticals?.includes('siteflow'));
		},
		workforceRepos() {
			return _.filter(this.repos, repo => repo?.verticals?.includes('workforce'));
		},
		piazzaRepos() {
			return _.filter(this.repos, repo => repo?.verticals?.includes('piazza'));
		},
		baasRepos() {
			return _.filter(this.repos, repo => repo?.verticals?.includes('baas'));
		},
		otherRepos() {
			return _.filter(this.repos, repo => repo?.verticals?.includes('other'));
		}
	},
	created() {
		this.fetchRepos();
	},
	methods: {
		...mapActions({ getAllRepos: 'analytics/getAllRepos' }),
		async fetchRepos() {
			this.isLoading = true;
			try {
				await this.getAllRepos();
			} catch (err) {
				this.$notify({
					type: 'error',
					text: 'An error happened while getting the list of the repos'
				});
			} finally {
				this.isLoading = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.ContentHeader {
	background: white;
	position: relative;
	&-loading {
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.Top {
	background: white;
	padding-bottom: 20px;
}
</style>
