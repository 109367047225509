<template>
	<div>
		<Datatable
			heading="Datatable"
			bordered
			:fields="fields"
			:per-page="10000"
			:items="items"
			:show-pagination="false"
			sort-by="score"
			:sort-desc="false"
		>
			<template #cell(name)="{item}">
				<a :href="item.url" target="_blank">{{ item.name }}</a>
			</template>
			<template #cell(loading)="{item}">
				<icon v-if="repoLoading[item.name]" name="circle-notch" spin />
			</template>
		</Datatable>
	</div>
</template>

<script>
import { Datatable } from '@oneflow/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import PQueue from 'p-queue';

const VULNERABILITIES = [
	'lowSeveritySecurityVulnerabilities',
	'moderateSeveritySecurityVulnerabilities',
	'highSeveritySecurityVulnerabilities',
	'criticalSeveritySecurityVulnerabilities'
];

const IMPACT_SCORES = {
	criticalSeveritySecurityVulnerabilities: 20,
	highSeveritySecurityVulnerabilities: 10,
	moderateSeveritySecurityVulnerabilities: 5,
	lowSeveritySecurityVulnerabilities: 1
};

export default {
	components: { Datatable },
	props: {
		repos: {
			type: Array,
			default: function() {
				return [];
			}
		}
	},
	data() {
		return {
			repoLoading: {}
		};
	},
	computed: {
		...mapGetters({ reposSecurity: 'analytics/reposSecurity' }),
		fields() {
			return [
				{ key: 'name', label: 'Name' },
				{ key: 'security.lowSeveritySecurityVulnerabilities', label: 'Low Severity', sortable: true },
				{
					key: 'security.moderateSeveritySecurityVulnerabilities',
					label: 'Moderate Severity',
					sortable: true
				},
				{ key: 'security.highSeveritySecurityVulnerabilities', label: 'High Severity', sortable: true },
				{
					key: 'security.criticalSeveritySecurityVulnerabilities',
					label: 'Critical Severity',
					sortable: true
				},
				{ key: 'score', label: 'Score', sortable: true },
				{ key: 'loading', label: '', class: 'Column-loader' }
			];
		},
		items() {
			return (this.repos || []).map(repo => {
				const score = VULNERABILITIES.reduce((score, vuln) => {
					const vulnerabilityValue = this.reposSecurity[repo.name]?.[vuln];
					const vulnerabilityScores = vulnerabilityValue * IMPACT_SCORES[vuln];
					return score - (vulnerabilityScores ?? 0);
				}, 0);
				return { ...repo, security: this.reposSecurity[repo.name], score };
			});
		}
	},
	watch: {
		repos: {
			immediate: true,
			handler: 'reposChanged'
		}
	},
	created() {
		this.queue = new PQueue({ concurrency: 10 });
	},
	methods: {
		...mapActions({ getRepoSecurity: 'analytics/getRepoSecurity' }),
		reposChanged: _.debounce(function() {
			this.repos.forEach(({ name }) =>
				this.queue.add(async () => {
					try {
						this.repoLoading = { ...this.repoLoading, [name]: true };
						await this.getRepoSecurity(name);
					} finally {
						this.repoLoading[name] = false;
					}
				})
			);
		}, 200)
	}
};
</script>

<style>
.Column-loader {
	min-width: 50px;
}
</style>
