<template>
	<div>
		<icon v-if="isLoading" name="circle-notch" spin />
		<b-form-checkbox v-else :checked="checked" @input="handleChange($event)" />
	</div>
</template>

<script>
export default {
	props: {
		vertical: { type: String, default: '' },
		repoVerticals: { type: Array, default: () => [] },
		isLoading: { type: Boolean }
	},
	computed: {
		checked() {
			return this.repoVerticals.includes(this.vertical);
		}
	},
	methods: {
		handleChange(checked) {
			let newVerticals = [...this.repoVerticals];
			if (checked) {
				newVerticals.push(this.vertical);
			} else {
				newVerticals.filter(v => v === this.vertical);
			}
			this.$emit('change', { verticals: newVerticals, added: checked });
		}
	}
};
</script>
