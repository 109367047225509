<template>
	<div class="p-3">
		<ContentHeader :title="title" no-padding class="mb-3" />
		<b-table bordered :fields="fields" :items="values">
			<template #cell()="data">
				<div :id="`${data.value.id}`">
					<span
						:class="{
							'text-danger': !isNil(data.value.checked) && data.value.checked < 80,
							'text-success':
								!isNil(data.value.checked) && data.value.checked >= 80 && data.value.checked < 100,
							'text-info': !isNil(data.value.checked) && data.value.checked === 100
						}"
					>
						{{ !isNil(data.value.checked) ? `${data.value.checked}%` : data.value.value }}
					</span>
					<span v-if="!isNil(data.value.checked)">({{ data.value.value }})</span>
					<b-popover
						v-if="!isNil(data.value.checked)"
						:target="`${data.value.id}`"
						triggers="hover"
						placement="bottom"
					>
						<template #title>Tasks Missing Checks</template>
						<span v-for="(url, index) in data.value.uncheckedTasks" :key="index">
							<template v-if="index < 3">
								<a target="_blank" class="form-text" :href="url">{{ url }}</a>
							</template>
						</span>
						<template v-if="data.value.uncheckedTasks && data.value.uncheckedTasks.length > 3">
							<br />
							<span class="text-muted"> + {{ data.value.uncheckedTasks.length - 3 }} hidden </span>
						</template>
					</b-popover>
				</div>
			</template>
		</b-table>
	</div>
</template>

<script>
import _ from 'lodash';
import { ContentHeader } from '@oneflow/ofs-vue-layout';

export default {
	components: { ContentHeader },
	props: {
		workflow: {
			type: Array,
			default: function() {
				return [];
			}
		},
		title: {
			type: String,
			default: ''
		}
	},
	computed: {
		fields() {
			return this.workflow.map(i => ({
				key: i.label,
				value: i.label,
				tdClass: 'text-muted'
			}));
		},
		values() {
			const values = {};
			this.workflow.forEach((i, index) => {
				const checkedPercentage = i.checked ? Number(i.checked.replace('%', '')) : undefined;

				values[i.label] = {
					checked: checkedPercentage,
					value: i.value,
					uncheckedTasks: i.uncheckedTasks,
					id: `${this.title}-${i.label}-${index}`
				};
			});
			return [values];
		}
	},
	methods: {
		isNil: _.isNil
	}
};
</script>

<style>
.Column-loader {
	min-width: 50px;
}
</style>
