<template>
	<LayoutProvider>
		<section class="Top pb-4">
			<ContentHeader title="QA Checkpoints" data-test-id="pageTitle">
				<div v-if="isLoading" class="ContentHeader-loading">
					<icon name="circle-notch" spin /> Fetching fresh data...
				</div>
			</ContentHeader>

			<p class="bg-white px-3 m-0 float-left">
				How many tasks have expected checkpoints, and therefore have been signed off?
			</p>

			<p class="bg-white px-3 m-0 text-muted float-right">Updated: {{ dateTime }}</p>
		</section>

		<div class="p-3">
			<ContentHeader title="Legend" no-padding class="mb-3" />
			<b-table :fields="legendFields" :items="legendItems" />
		</div>

		<div v-for="(workflow, name) in workflows" :key="workflow.label" class="wrapper">
			<Table :workflow="workflow" :title="name" />
		</div>
	</LayoutProvider>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import { ContentHeader } from '@oneflow/ofs-vue-layout';
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/circle-notch';
import Table from './Table';
import LayoutProvider from '../../../components/layouts/DefaultLayout';
import moment from 'moment';

export default {
	components: { LayoutProvider, Table, Icon, ContentHeader },
	data() {
		return {
			isLoading: false,
			legendFields: [
				{
					key: 'low',
					value: 'Too Low',
					tdClass: 'text-danger'
				},
				{
					key: 'high',
					value: 'High',
					tdClass: 'text-success'
				},
				{
					key: 'target',
					value: 'PIB QA Target',
					tdClass: 'text-info'
				}
			],
			legendItems: [
				{
					low: '< 80%',
					high: '80 - 99%',
					target: '100%'
				}
			]
		};
	},
	computed: {
		...mapGetters({
			workflows: 'analytics/workflowsCount'
		}),
		dateTime() {
			return moment(this.workflows.dateTime).format('MM/DD/YYYY HH:mm');
		}
	},
	created() {
		this.fetchWorkflowsCount();
	},
	methods: {
		...mapActions({ getAllWorkflowsCount: 'analytics/getAllWorkflowsCount' }),
		async fetchWorkflowsCount() {
			this.isLoading = true;
			try {
				await this.getAllWorkflowsCount();
			} catch (err) {
				this.$notify({
					type: 'error',
					text: 'An error happened while counting all tasks within workflows'
				});
			} finally {
				this.isLoading = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.Top {
	background: white;
	padding-bottom: 20px;
}
</style>
