<template>
	<div class="scale">
		<span class="scale-label" :class="`scale-label--${value}`" v-text="label"></span>
		<ul class="scale-indicators">
			<li class="scale-indicator" :class="getClass(value, 1)"></li>
			<li class="scale-indicator" :class="getClass(value, 2)"></li>
			<li class="scale-indicator" :class="getClass(value, 3)"></li>
			<li class="scale-indicator" :class="getClass(value, 4)"></li>
			<li class="scale-indicator" :class="getClass(value, 5)"></li>
		</ul>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			required: true,
			type: Number
		}
	},
	computed: {
		label() {
			if (this.value === 1) {
				return 'Lowest';
			} else if (this.value === 2) {
				return 'Low';
			} else if (this.value === 3) {
				return 'Some';
			} else if (this.value === 4) {
				return 'High';
			}
			return 'Highest';
		}
	},
	methods: {
		getClass(value, scalePosition) {
			let scaleClass = '';
			if (value === 5 && scalePosition <= 5) {
				scaleClass = 'scale-indicator--5';
			} else if (value === 4 && scalePosition <= 4) {
				scaleClass = 'scale-indicator--4';
			} else if (value === 3 && scalePosition <= 3) {
				scaleClass = 'scale-indicator--3';
			} else if (value === 2 && scalePosition <= 2) {
				scaleClass = 'scale-indicator--2';
			} else if (value === 1 && scalePosition <= 1) {
				scaleClass = 'scale-indicator--1';
			}
			return scaleClass;
		}
	}
};
</script>

<style lang="scss" scoped>
.scale {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 15px;

	&-label {
		line-height: 1.2;
		font-size: 20px;
		font-weight: bold;
		padding-bottom: 5px;

		&--1,
		&--2,
		&--3 {
			color: #c70000;
		}

		&--4,
		&--5 {
			color: #4fd36d;
		}
	}

	&-indicators {
		display: flex;
		flex-direction: row;
		margin: 0;
		padding: 6px 0px 6px 0px;
		list-style: none;
	}

	&-indicator {
		width: 10px;
		height: 10px;
		margin: 0 2px;
		display: flex;
		background: #eee;
		border-radius: 5px;

		&--1,
		&--2,
		&--3 {
			background: #c70000;
		}

		&--4,
		&--5 {
			background: #4fd36d;
		}
	}
}
</style>
