import Overview from './Overview';
// import Home from './Home';
// import Areas from './Areas';

const routes = [
	{
		name: 'overview',
		path: '/overview',
		component: Overview
	}
];

export default routes;
