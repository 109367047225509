import Vue from 'vue';
import OfsCrud from '@oneflow/ofs-vuex-crud';
import httpProviderInstance from './httpProviderInstance';
import registerAuthModule from './auth';
import registerAccountModule from './account';
import registerAnalyticsModule from './analytics';
import registerLangStoreModule from './lang';
import registerNotificationStoreModule from './version-notification';

const setupModules = (store, i18n) => {
	Vue.use(OfsCrud, { store });
	Vue.$ofsCrud.registerTransport({ name: 'http', fn: () => httpProviderInstance });

	// Register Modules
	registerAuthModule();
	registerAccountModule(store);
	registerLangStoreModule(store, i18n);
	registerAnalyticsModule(store);
	registerNotificationStoreModule(store);
};

export default setupModules;
