import AssignVerticals from './AssignVerticals/AssignVerticals';

const routes = [
	{
		name: 'settings.assign-verticals',
		path: '/settings/assign-verticals',
		component: AssignVerticals
	}
];

export default routes;
