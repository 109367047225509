import TestCoverage from './TestCoverage/TestCoverage';
import SecurityCoverage from './SecurityCoverage/SecurityCoverage';
import Sentry from './Sentry/Sentry';
import Checkpoints from './Checkpoints/Checkpoints';

const routes = [
	{
		path: '/improvements/test-coverage',
		name: 'improvements.test-coverage',
		component: TestCoverage
	},
	{
		path: '/improvements/security-coverage',
		name: 'improvements.security-coverage',
		component: SecurityCoverage
	},
	{
		path: '/improvements/sentry',
		name: 'improvements.sentry',
		component: Sentry
	},
	{
		path: '/improvements/checkpoints',
		name: 'improvements.checkpoints',
		component: Checkpoints
	}
];

export default routes;
